import UnauthenticatedRoute from 'smile-admin/routes/unauthenticated';

export default UnauthenticatedRoute.extend({
  setupController(controller) {
    this._super(...arguments);

    controller.setProperties(
      this.controllerFor('auth').getProperties(
        'email',
        'hasRecoveredPassword',
        'hasResetPassword',
        'isResetPasswordTokenExpired'
      )
    );
  },
});
