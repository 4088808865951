import { helper as buildHelper } from '@ember/component/helper';
import { isPresent } from '@ember/utils';
import moment from 'moment-timezone';

export function isValidMoment(momentToValidate) {
  return (
    isPresent(momentToValidate) &&
    moment.isMoment(momentToValidate) &&
    momentToValidate.isValid()
  );
}

export default buildHelper(([momentToValidate]) =>
  isValidMoment(momentToValidate)
);
