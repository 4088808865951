/* import __COLOCATED_TEMPLATE__ from './pages.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LauncherPageVisibilityComponent extends Component {
  @service featureRollouts;

  @tracked isEditingConditions = false;
}
