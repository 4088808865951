import Service, { inject as service } from '@ember/service';
import { get, computed, action } from '@ember/object';
import { readOnly, reads } from '@ember/object/computed';
import { assert } from '@ember/debug';
import { capitalize } from '@ember/string';
import { isEmpty, isPresent } from '@ember/utils';
import { scheduleOnce } from '@ember/runloop';
import numeral from 'numeral';
import { storageFor } from 'ember-local-storage';
import {
  all,
  timeout,
  waitForProperty,
  task,
  dropTask,
  dropTaskGroup,
  lastValue,
} from 'ember-concurrency';
import { isPaymentRequiredError } from '@smile-io/ember-smile-core/adapters/errors';
import State from 'smile-admin/objects/account-billing-state';
import { subscriptionTemplateFor } from 'smile-admin/utils/decorators/billing';
import config from 'smile-admin/config/environment';
import { slugs } from 'smile-admin/models/billing-subscription-template';
import { states } from 'smile-admin/models/billing-subscription';
import BillingSubscriptionTemplateData from 'smile-admin/objects/billing-subscription-template-data';

const adaptersToTrackBillingEvents = ['GoogleTagManager'];

// Max retries for activating a Stripe subscription
const MAX_STRIPE_RETRIES = 1;

export default class BillingService extends Service {
  @service alert;
  @service errorHandler;
  @service featureRollouts;
  @service intercom;
  @service metrics;
  @service sesh;
  @service store;
  @service utils;

  @storageFor('sweet-tooth-session')
  sessionStorage;

  /**
   * Task group for billing operations: subscribe/activate/decline/etc.
   * Uses to forbid closing modals/changing routes while this is running.
   *
   * @type {TaskGroup}
   * @public
   */
  @dropTaskGroup
  operations;

  /**
   * When the merchant accepts an offer or self-upgrades for the first time, we need to show
   * the Stripe Checkout modal to the merchant. This will be set when that's the case and
   * components can use this together with {{stripe-checkout}} to handle this.
   *
   * @type {DS.Model}
   * @default null
   * @public
   */
  activateableStripeSubscription = null;

  /**
   * When true, components using this should show the Stripe Checkout modal.
   *
   * @type {Boolean}
   * @default false
   * @public
   */
  showStripeCheckout = false;

  /**
   * True, when should show the Stripe Checkout modal for updating Stripe credit card.
   *
   * @type {Boolean}
   * @default false
   * @public
   */
  isUpdatingStripePayment = false;

  @reads('sesh.account')
  account;

  /**
   * All the billing-subscription-templates.
   *
   * @type {DS.Model[]}
   * @default []
   * @public
   */
  @lastValue('fetchSubscriptionTemplates')
  subscriptionTemplates = [];

  /**
   * The current account's chargeable billing-subscription.
   *
   * @type {DS.Model}
   * @public
   */
  @readOnly('_state.chargeableSubscription')
  chargeableSubscription;

  /**
   * If exists, the billing-subscription offer for this account.
   *
   * @type {DS.Model}
   * @public
   */
  @readOnly('_state.subscriptionOffer')
  subscriptionOffer;

  /**
   * True, when the merchant has a billing-subscription offer.
   *
   * @type {DS.Model}
   * @public
   */
  @readOnly('_state.hasSubscriptionOffer')
  hasSubscriptionOffer;

  @readOnly('_state.flags.hasAcceptedOffer')
  hasAcceptedOffer;

  @readOnly('_state.flags.hasDeclinedOffer')
  hasDeclinedOffer;

  @readOnly('_state.flags.hasUpgraded')
  hasUpgraded;

  @readOnly('_state.flags.hasSwitchedToAnnual')
  hasSwitchedToAnnual;

  @readOnly('_state.flags.hasDowngraded')
  hasDowngraded;

  @readOnly('_state.flags.hasUpdatedPaymentMethod')
  hasUpdatedPaymentMethod;

  @(computed(
    'chargeableSubscription.{isDelinquent,hasPaymentMethod,billingPaymentMethod.isExpired}'
  ).readOnly())
  get isPaymentUpdateRequired() {
    let chargeableSubscription = this.chargeableSubscription;
    // If the subscription is delinquent
    if (chargeableSubscription.isDelinquent) {
      return true;
    }

    // If the subscription has a payment method, check if the card is expired (only applies to Stripe currently)
    return (
      chargeableSubscription.hasPaymentMethod &&
      chargeableSubscription.get('billingPaymentMethod.isExpired')
    );
  }

  @computed
  get _state() {
    return State.create({ account: this.account });
  }

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'free',
  })
  freeSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'starter',
  })
  starterSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'starterWithOrderLimit',
  })
  starterWithOrderLimitSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'legacyGrowth',
  })
  legacyGrowthSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'growth',
  })
  growthSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'growthWithOrderLimit',
  })
  growthWithOrderLimitSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'pro',
  })
  proSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'plus',
  })
  plusSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'enterprise',
  })
  enterpriseSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'wixStandard',
  })
  wixStandardSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'wixPremium',
  })
  wixPremiumSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'starter',
    interval: 'year',
  })
  starterYearlySubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'starterWithOrderLimit',
    interval: 'year',
  })
  starterYearlyWithOrderLimitSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'growth',
    interval: 'year',
  })
  growthYearlySubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'growthWithOrderLimit',
    interval: 'year',
  })
  growthYearlyWithOrderLimitSubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'pro',
    interval: 'year',
  })
  proYearlySubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'plus',
    interval: 'year',
  })
  plusYearlySubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'wixStandard',
    interval: 'year',
  })
  wixStandardYearlySubscriptionTemplate;

  @subscriptionTemplateFor('subscriptionTemplates', {
    slug: 'wixPremium',
    interval: 'year',
  })
  wixPremiumYearlySubscriptionTemplate;

  get supportsAnnualSubscriptions() {
    return (
      this.featureRollouts.get('paidPlansWithYearlySubscriptions') &&
      !this.account.hasShopifyPreferredBillingProvider
    );
  }

  @dropTask
  *fetchSubscriptionTemplates() {
    let subscriptionTemplates = yield this.store.query(
      'billing-subscription-template',
      {
        include:
          'billing_subscription_template_product_plans.new_billing_plan.billing_product',
        is_public: true,
      }
    );

    return subscriptionTemplates;
  }

  @dropTask
  *fetchSubscriptionTemplate(slug) {
    return yield this.store.queryRecord('billing-subscription-template', {
      slug,
    });
  }

  @task
  *querySubscriptionTemplates(params = {}) {
    try {
      return yield this.store.query('billing-subscription-template', {
        include:
          'billing_subscription_template_product_plans.new_billing_plan.billing_product',
        ...params,
      });
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  /**
   * Fetches the account with billing-subscriptions and feature-flags.
   *
   * @param {Boolean} refreshState  Whether it should update the billing state. True,
   *                                by default, but we don't want this when called from
   *                                other billing operations.
   * @public
   */
  @dropTask
  *fetchAccountWithSubscriptions({ refreshState = true } = {}) {
    // For Shopify & Wix we kick off these tasks on the route asynchronously,
    // so for that reason we make sure to wait until this task is `idle`
    // because it might lead to a subscription change (if they approved the
    // payment)
    yield all([
      waitForProperty(this, 'processShopifyPaymentsCallback.isIdle'),
      waitForProperty(this, 'completeWixCheckout.isIdle'),
    ]);

    let includes = [
      'usage_credits',
      'new_feature_flags',
      'reward_programs',
      'billing_subscriptions.billing_payment_method',
      'billing_subscriptions.active_billing_discount.billing_coupon',
      'billing_subscriptions.pending_billing_discount.billing_coupon',
      'billing_subscriptions.billing_subscription_items.new_billing_plan.billing_plan_usage_allowance',
      'billing_subscriptions.billing_subscription_items.new_billing_plan.billing_product',
      'billing_subscriptions.billing_subscription_defaults.new_billing_plan.billing_product',
      'billing_subscriptions.billing_subscription_defaults.new_billing_plan.billing_plan_usage_allowance',
    ].join(',');

    let account = yield this.store.findRecord('account', this.sesh.account.id, {
      include: includes,
      reload: true,
    });

    // Manually refresh, so we have updated account active/offered subscriptions
    if (refreshState) {
      this.updateState();
    }

    return account;
  }

  @dropTask
  *fetchBillingSubscriptionOffers() {
    let includes = [
      'pending_billing_discount.billing_coupon',
      'billing_subscription_items.new_billing_plan.billing_plan_usage_allowance',
      'billing_subscription_items.new_billing_plan.billing_product',
      'billing_subscription_defaults.new_billing_plan.billing_product',
      'billing_subscription_defaults.new_billing_plan.billing_plan_usage_allowance',
    ].join(',');

    yield this.store.query('billing-subscription', {
      include: includes,
      account_id: this.sesh.account.id,
      state: states.offered,
    });

    this.updateState();
  }

  /**
   * Activates a billing subscription offer (in `offered` state).
   *
   * @param {DS.Model} subscription       The billing-subscription to be activated.
   * @param {Boolean} refreshFeatureFlags When true, will reload the account with subscriptions
   *                                      and feature flags. Default: `true`
   * @returns {DS.Model|undefined}        The activated billing-subscription on success,
   *                                      undefined otherwise
   * @public
   */
  @task({ group: 'operations' })
  *activateSubscription(subscription, refreshFeatureFlags = true) {
    if (!subscription.isOffered) {
      assert(
        '[billing service] - Activating a billing subscription not in `offered` state is not possible',
        true
      );
      return;
    }

    // If it's a Shopify `offered` billing-subscription, we redirect to Shopify
    // Payments for approving/declining the subscription and then we are redirected back.
    // Since we have no way of knowing if it was an offer or self-upgrade once the
    // merchant is redirected back, we use this flag in local-storage.
    if (subscription.isShopifyType) {
      this.set('sessionStorage.hasShopifyOffer', true);
    }

    try {
      subscription = yield this._activateSubscription.perform(subscription);

      // If the subscription was not activated (ex: Stripe modal closed without entering card), bail
      if (!subscription || !get(subscription, 'isActive')) {
        return;
      }

      if (refreshFeatureFlags) {
        yield this.fetchAccountWithSubscriptions.perform({
          refreshState: false,
        });
      }

      this.updateFlags({ hasAcceptedOffer: true });

      this.alert.info('Offer accepted');

      return subscription;
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  /**
   * Declines a billing-subscription offer (in `offered` state).
   *
   * @param {DS.Model} subscription  The billing-subscription offer to decline.
   * @returns {DS.Model|undefined}   The declined billing-subscription.
   * @public
   */
  @task({ group: 'operations' })
  *declineSubscription(subscription) {
    try {
      if (!subscription.isOffered) {
        assert(
          '[billing service] - Declining a billing subscription not in `offered` state is not possible',
          true
        );
        return;
      }
      let declinedSubscription = yield subscription.declineOffer();

      this.updateFlags({ hasDeclinedOffer: true });

      this.alert.info('Offer declined');

      return declinedSubscription;
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  /**
   * Subscribes the merchant to a billing-subscription-template
   *
   * @param {DS.Model} subscriptionTemplate               The billing-subscription-template to subscribe to.
   * @param {Object} options                              Extra options for this process.
   * @param {Boolean} options.refreshFeatureFlags         When true, will reload the account with subscriptions
   *                                                      and feature flags. Default: `true`
   * @returns {DS.Model|undefined}                        The activated billing-subscription on success,
   *                                                      undefined otherwise
   * @public
   */
  @task({ group: 'operations' })
  subscribeToSubscriptionTemplate = {
    *perform(subscriptionTemplate, options = {}) {
      // `In encapsulated tasks `this` points to the currently running TaskInstance,
      // rather than the host object that the task lives on, so we use the task's context
      // to access anything outside this task
      let { context } = this;
      let { refreshFeatureFlags = true, downgradeDetails = null } = options;

      try {
        let subscription =
          yield this._subscribeToTemplate(subscriptionTemplate);

        subscription = yield context._activateSubscription.perform(
          subscription,
          downgradeDetails
        );

        // If the subscription was not activated (ex: Stripe modal closed without entering card), bail
        if (!subscription || !subscription.isActive) {
          return;
        }

        if (refreshFeatureFlags) {
          yield context.fetchAccountWithSubscriptions.perform({
            refreshState: false,
          });
        }

        let hasDowngraded =
          (context.chargeableSubscription.isPlus &&
            (subscription.isGrowthWithOrderLimit ||
              subscription.isStarterWithOrderLimit ||
              subscription.isFree)) ||
          (context.chargeableSubscription.isGrowthWithOrderLimit &&
            (subscription.isStarterWithOrderLimit || subscription.isFree)) ||
          (context.chargeableSubscription.isStarterWithOrderLimit &&
            subscription.isFree);

        let hasSwitchedToAnnual =
          context.chargeableSubscription.basePlan.billingProduct.slug ===
            subscription.basePlan.billingProduct.slug &&
          context.chargeableSubscription.basePlan !== subscription.basePlan;

        context.updateFlags({
          hasUpgraded: !hasDowngraded && !hasSwitchedToAnnual,
          hasSwitchedToAnnual,
          hasDowngraded,
        });

        if (context.hasUpgraded) {
          context.alert.info('Plan upgraded');
        } else if (hasSwitchedToAnnual) {
          context.alert.info('Switched to annual');
        } else if (hasDowngraded) {
          context.alert.info('Plan downgraded');
        }

        return subscription;
      } catch (err) {
        context.errorHandler.handle(err);
      }
    },

    _subscribeToTemplate: async function (subscriptionTemplate) {
      let subscribeIncludes = [
        'billing_subscription_defaults.new_billing_plan.billing_product',
        'billing_subscription_defaults.new_billing_plan.billing_plan_usage_allowance',
      ];

      let subscription = await subscriptionTemplate.subscribe({
        include: subscribeIncludes.join(','),
      });

      return subscription;
    },
  };

  /**
   * Allows merchants to update their payment method when the billing-subscription
   * is delinquent or (for Stripe) the credit card is expired.
   *
   * Shopify subscription - redirects to the Shopify store's admin billing settings page
   * Stripe subscription - shows Stripe Checkout and allows merchants to add a new card
   *
   * @public
   */
  @task({ group: 'operations' })
  *updatePaymentMethod() {
    let chargeableSubscription = this.chargeableSubscription;

    try {
      if (chargeableSubscription.isShopifyType) {
        this.utils.openExternalLink(
          `${this.account.url}/admin/settings/billing`,
          {
            newTab: false,
            replace: false,
          }
        );

        // Wait 2 minutes...just long enough to get redirected to Shopify
        yield timeout(2 * 60 * 1000);
      } else if (chargeableSubscription.isStripeType) {
        this.set('isUpdatingStripePayment', true);

        let stripeToken = yield this._waitForStripeToken.perform();

        // Reset `isUpdatingStripePayment`
        this.set('isUpdatingStripePayment', false);

        // If we don't have a token, Stripe checkout was closed without entering details
        // In this case we done here
        if (isEmpty(stripeToken)) {
          return;
        }

        yield this.account.updateStripePaymentSource({
          card_token: stripeToken,
          include: 'billing_subscriptions.billing_payment_method',
        });

        this.updateFlags({ hasUpdatedPaymentMethod: true });

        this.alert.info('Payment method updated');
      }
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  @dropTask
  *_activateSubscription(subscription, downgradeDetails = null) {
    let activatorTask = `_activate${capitalize(subscription.type)}Subscription`;

    if (subscription.isFreeType) {
      activatorTask = '_activateFreeSubscription';
    }

    for (let i = 0; i <= MAX_STRIPE_RETRIES; i++) {
      let forceShowStripeModal = i > 0;

      let activationParams = [subscription];

      if (subscription.type === 'stripe') {
        activationParams.push(forceShowStripeModal);
      }

      if (downgradeDetails) {
        activationParams.push(downgradeDetails);
      }

      try {
        subscription = yield this.get(activatorTask).perform(
          ...activationParams
        );

        // If we have a subscription (activated successfully), track it
        if (subscription) {
          this.trackFirstTimePaidSubscription.perform(subscription.basePlan);
        }

        return subscription;
      } catch (err) {
        // Retry activating subscription
        //
        // This is basically for Stripe, where if the merchant inputs a card that is valid,
        // but we fail to charge the card on the API, we show the Stripe Checkout modal
        // again, so they can input another card - tldr; if error is a PaymentRequiredError
        // don't throw, and try again
        if (!isPaymentRequiredError(err) || i === MAX_STRIPE_RETRIES) {
          throw err;
        } else {
          this.errorHandler.handle(err);
        }
      }
    }
  }

  /**
   * Handles activating a Free type billing-subscription.
   * @returns {DS.Model|undefined}  The activated billing-subscription on success,
   *                                undefined otherwise
   */
  @dropTask
  *_activateFreeSubscription(subscription, downgradeDetails = null) {
    // Activate the billing subscription
    let activatedSubscription = yield subscription.activate({
      account_downgrade_details: downgradeDetails,
    });

    return activatedSubscription;
  }

  /**
   * Handles activating a Stripe type billing-subscription.
   *
   * @param {DS.Model}  subscription          The Stripe billing-subscription to activate.
   * @param {Boolean}   forceShowStripeModal  Forces to show the Stripe Checkout modal to the
   *                                          merchant, even if they already have billing info.
   * @params {} downgradeDetails
   * @returns {DS.Model|undefined}            The activated billing-subscription on success,
   *                                          undefined otherwise
   */
  @dropTask
  *_activateStripeSubscription(
    subscription,
    forceShowStripeModal = false,
    downgradeDetails = null
  ) {
    let stripeToken;

    // If we don't have billing info, require the merchant to input card details with Stripe
    if (!this.account.hasBillingInfo || forceShowStripeModal) {
      // Set the billing-subscription as pending, so we start loading Stripe Checkout
      this.set('activateableStripeSubscription', subscription);

      stripeToken = yield this._waitForStripeToken.perform();

      // Reset `activateableStripeSubscription`
      this.set('activateableStripeSubscription', null);

      // If we don't have a token, Stripe checkout was closed without entering details
      // In this case we done here
      if (isEmpty(stripeToken)) {
        return;
      }
    }

    // Activate the billing subscription
    let activatedBillingSubscription = yield subscription.activate({
      card_token: stripeToken,
      account_downgrade_details: downgradeDetails,
    });

    return activatedBillingSubscription;
  }

  @dropTask
  *_waitForStripeToken() {
    // Show Stripe Checkout modal for merchant to enter credit card and reset flag
    // used to wait until modal is closed
    this.setProperties({
      showStripeCheckout: true,
      stripeCheckoutClosed: false,
    });

    // Wait until the Stripe payment is done/cancelled
    yield waitForProperty(
      this,
      'stripeCheckoutClosed',
      (stripeCheckoutClosed) => !!stripeCheckoutClosed
    );

    // Reset these back
    this.setProperties({
      showStripeCheckout: false,
      stripeCheckoutClosed: true,
    });

    return this.get('stripeToken.id');
  }

  /**
   * Handles activating a Shopify type billing-subscription.
   *
   * Shopify payment processing - Step 1/2
   * Hits the `create_shopify_charge` API endpoint and redirects the merchant to
   * Shopify to approve/decline payment.
   *
   * @param {DS.Model} subscription The billing-subscription to create the Shopify charge for.
   * @param {} downgradeDetails
   * @private
   */
  @dropTask
  *_activateShopifySubscription(subscription, downgradeDetails) {
    let return_url_path;
    if (window.location.pathname === '/start') {
      return_url_path = '/';
    }

    yield subscription.createShopifyCharge({ return_url_path });

    if (isPresent(downgradeDetails)) {
      this.set('sessionStorage.downgradeDetails', downgradeDetails);
    }

    this.utils.openExternalLink(
      subscription.get('shopifyRecurringApplicationChargeConfirmationUrl'),
      {
        newTab: false,
        replace: false,
      }
    );

    // Waiting for 2 minutes, just long enough until we redirect to the Shopify store for payment.
    // We want this to avoid flaky UX where we close the modal and then we slowly redirect to
    // Shopify Payments
    yield timeout(2 * 60 * 1000);
  }

  /**
   * Handles activating a Wix type billing-subscription.
   *
   * Wix checkout processing - Step 1/2
   *
   * NOTE: We're not storing `downgradeDetails` in session storage similar to Shopify, because you
   * can't downgrade currently on Wix.
   *
   * @param {DS.Model} subscription The billing-subscription to create the Wix charge for.
   * @param {} downgradeDetails
   * @private
   */
  @dropTask
  *_activateWixSubscription(subscription /* , downgradeDetails */) {
    yield subscription.createWixCheckout();

    this.utils.openExternalLink(subscription.wixCheckoutUrl, {
      newTab: false,
      replace: false,
    });

    // Waiting for 2 minutes, just long enough until we redirect to the Wix checkout for payment so
    // that we avoid flaky UX where we close the modal and then we slowly redirect to Wix Checkout
    yield timeout(2 * 60 * 1000);
  }

  /**
   * Handles activating a Manual type billing-subscription.
   * @returns {DS.Model|undefined}  The activated billing-subscription on success,
   *                                undefined otherwise
   */
  @dropTask
  *_activateManualSubscription(subscription) {
    // Activate the billing subscription
    let activatedSubscription = yield subscription.activate();
    return activatedSubscription;
  }

  @dropTask
  *trackFirstTimePaidSubscription(upgradedBasePlan) {
    let {
      sesh: { account },
    } = this;

    yield account.reload();

    if (
      upgradedBasePlan.amountCents === 0 ||
      account.hasPreviouslyPaidBillingSubscription
    ) {
      return;
    }

    let value = numeral(upgradedBasePlan.amountCents / 100).format('0.00');
    let currency = account.currency.isoCode;

    this.metrics.trackEvent('GoogleTagManager', {
      event: 'Subscribe',
      value,
      currency,
      interval: upgradedBasePlan.interval,
      interval_count: upgradedBasePlan.intervalCount,
    });
  }

  /**
   * Handles activating a Shopify type billing-subscription.
   *
   * Shopify payment processing - Step 2/2
   * Once merchants approves/declines the charge, he's redirected to our app.
   * We hit the `shopify_payments_callback` API endpoint with the Shopify `charge_id`
   *
   * @param {String} chargeId The Shopify chargeID received from Shopify Payments.
   * @public
   */
  @dropTask
  *processShopifyPaymentsCallback(chargeId) {
    try {
      // TODO we should move away from doing this, so awkward, instead would be nicer
      // to just use `fetch` and do standard calls, pretty much same thing but cleaner
      let subscription = this.store.createRecord('billing-subscription');

      let downgradeDetails = this.sessionStorage.get('downgradeDetails');
      let areDowngradeDetailsPresent =
        isPresent(downgradeDetails) && typeof downgradeDetails === 'object';

      let requestBody = { charge_id: chargeId };

      if (areDowngradeDetailsPresent) {
        requestBody['account_downgrade_details'] = downgradeDetails;
      }

      let updatedSubscription =
        yield subscription.shopifyPaymentsCallback(requestBody);

      // Destroy the created billing-subscription used to do the API call
      subscription.destroyRecord();

      // If, we know this is an offer, set appropriate flag
      if (
        this.get('sessionStorage.hasShopifyOffer') &&
        updatedSubscription.isShopifyType
      ) {
        this.updateFlags({
          hasAcceptedOffer: updatedSubscription.isActive,
        });
      } else if (areDowngradeDetailsPresent) {
        this.updateFlags({
          hasDowngraded: updatedSubscription.isActive,
        });
      } else {
        this.updateFlags({
          hasUpgraded: updatedSubscription.isActive,
        });
      }

      let message;
      if (this.hasAcceptedOffer) {
        message = 'Offer accepted';
      } else if (this.hasUpgraded) {
        message = 'Plan upgraded';
      } else if (this.hasDowngraded) {
        message = 'Plan downgraded';
      }

      scheduleOnce('actions', this, this.alert.info, message);

      // Reset local-storage
      this.set('sessionStorage.hasShopifyOffer', false);
      this.set('sessionStorage.downgradeDetails', null);

      this.trackFirstTimePaidSubscription.perform(updatedSubscription.basePlan);

      return updatedSubscription;
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  /**
   * Handles completing a Wix checkout for a billing-subscription upgrade.
   *
   * Wix checkout processing - Step 2/2
   * Once merchants approves/declines the charge, they're redirected to our app. We hit the
   * `complete_wix_checkout` API endpoint to finish the process.
   *
   * @param {String} subscriptionId
   * @public
   */
  @dropTask
  *completeWixCheckout(subscriptionId) {
    try {
      let subscription = yield this.store.findRecord(
        'billing-subscription',
        subscriptionId
      );

      // If the subscription is not in pending state nothing to activate
      if (!subscription.isPending) {
        return subscription;
      }

      let newSubscription = yield subscription.completeWixCheckout();

      // If the checkout was completed, we should have the subscription in active state otherwise
      // we're done here
      if (!newSubscription.isActive) {
        return newSubscription;
      }

      this.updateFlags({
        hasUpgraded: true,
      });

      this.alert.info('Plan upgraded');

      this.trackFirstTimePaidSubscription.perform(newSubscription.basePlan);

      return newSubscription;
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  /**
   * Method to be invoked with the token received from Stripe after merchant inputs their credit card.
   *
   * @param {Object} token The token object received from Stripe https://stripe.com/docs/api#token_object
   * @public
   */
  processStripeToken(token) {
    this.setProperties({
      stripeCheckoutClosed: true,
      stripeToken: token,
      showStripeCheckout: false,
    });
  }

  /**
   * Refreshes the account's billing state (active/offered subscriptions, etc)
   *
   * @public
   */
  updateState() {
    this._state.updateState();
  }

  /**
   * Update the billing flags
   *
   * @param {Object} flags
   * @public
   */
  updateFlags(flags = {}) {
    this._state.updateFlags(flags);
  }

  /**
   * Reset billing flags
   * @public
   */
  resetFlags() {
    this._state.updateFlags();
  }

  /**
   * Handles contacting the sales team
   *
   * @param {String} message
   * @public
   */
  contactSales(message) {
    this.intercom.showNewMessage(message);
  }

  /**
   * Records billing events to external services
   *
   * @param {String} event
   * @param {Object} eventData
   * @public
   */
  trackEvent(event, eventData = {}) {
    adaptersToTrackBillingEvents.forEach((adapter) => {
      this.metrics.trackEvent(adapter, {
        ...eventData,
        event,
      });
    });
  }

  get _upgradeSource() {
    return this.sessionStorage.get('_upgradeSource');
  }
  /**
   * Allows for setting an upgrade source from any page in the app and once the merchant ends up
   * upgrading on the billing page fire a custom VWO converstion goal.
   *
   * NOTE  __source__ will be processed by removing any '.' and camelizing the string. This is
   * expected to match a `customTestingGoalIDs` key.
   */
  @action
  setUpgradeSource(source) {
    if (config.smileEnv !== 'production') {
      console.log(`[Billing] Setting upgrade source to ${source}`);
    }
    this.sessionStorage.set('_upgradeSource', source);
  }

  get recommendedPlanSlug() {
    if (this.fetchSubscriptionTemplates.isRunning) {
      return '';
    }

    const orderCounts = this.account.ordersCountToPlanRecommendation;

    const starterTemplateData = new BillingSubscriptionTemplateData(
      this.starterWithOrderLimitSubscriptionTemplate
    );

    const isOnFreeAndAlreadyExceededStarterMonthlyOrders =
      this.chargeableSubscription.isFreeType &&
      this.sesh.account.ordersCountToPlanRecommendation >
        starterTemplateData.basePlanMonthlyOrders;

    if (
      orderCounts > 6500 ||
      this.chargeableSubscription.isPlus ||
      this.chargeableSubscription.isPro ||
      this.chargeableSubscription.Enterprise
    ) {
      return slugs.plus;
    } else if (
      orderCounts > 500 ||
      isOnFreeAndAlreadyExceededStarterMonthlyOrders ||
      this.chargeableSubscription.isGrowth ||
      this.chargeableSubscription.isLegacySmallBusiness ||
      this.chargeableSubscription.isLegacyBasic ||
      this.chargeableSubscription.isGrowthWithOrderLimit
    ) {
      return slugs.growthWithOrderLimit;
    } else if (
      orderCounts < 200 &&
      (this.chargeableSubscription.isStarter ||
        this.chargeableSubscription.isStarterWithOrderLimit ||
        this.chargeableSubscription.isFreeType)
    ) {
      return slugs.starterWithOrderLimit;
    }

    return '';
  }

  isCurrentBillingProduct(subscriptionTemplate) {
    return (
      this.chargeableSubscription?.basePlan?.billingProduct ===
      subscriptionTemplate.basePlan.billingProduct
    );
  }

  isCurrentBillingPlan(subscriptionTemplate) {
    return (
      this.chargeableSubscription?.basePlan === subscriptionTemplate.basePlan
    );
  }

  isChargeableSubscriptionHigherThan(subscriptionTemplate) {
    if (
      this.chargeableSubscription?.isPlus &&
      !subscriptionTemplate.hasPlusSlug
    ) {
      return true;
    }

    if (
      this.chargeableSubscription?.isGrowthWithOrderLimit &&
      !subscriptionTemplate.hasPlusSlug &&
      !subscriptionTemplate.hasGrowthWithOrderLimitSlug
    ) {
      return true;
    }

    if (
      this.chargeableSubscription?.isStarterWithOrderLimit &&
      subscriptionTemplate.isFreeType
    ) {
      return true;
    }

    return false;
  }

  showAsCurrentSubscription(subscriptionTemplate) {
    if (this.isCurrentBillingPlan(subscriptionTemplate)) {
      // If it matches the exact base billing plan (which means it matches billing product too)
      return true;
    }

    if (this.isCurrentBillingProduct(subscriptionTemplate)) {
      // If it matches the billing product but can upgrade to yearly
      return true;
    }

    return false;
  }
}
