import { isValidMoment } from 'smile-admin/helpers/is-valid-moment';
import moment from 'moment-timezone';

function validateMoment({ getTargetMoment, message, comparisonFn }) {
  return (key, newValue, oldValue, changes, content) => {
    let newValueAsMoment = newValue;

    // This is a workaround for the fact that whilst the `oldValue` here is a
    // moment instance, `newValue` is a plain object with the properties of the
    // underlying moment instance copied onto it and as such does not have methods
    // that are useful for validating it. By setting the prototype of a copy of
    // `newValue` to `moment`'s prototype, it gains access to all of those methods.
    // See https://github.com/validated-changeset/validated-changeset/issues/193.
    if (
      newValue &&
      typeof newValue === 'object' &&
      newValue.constructor.prototype !== moment.prototype // handles case that newValue is already a moment.
    ) {
      newValueAsMoment = { ...newValue };
      Object.setPrototypeOf(newValueAsMoment, moment);
    }

    if (!isValidMoment(newValueAsMoment)) {
      // For current usage, assuming no value is acceptable for this validation.
      return true;
    }

    const target = getTargetMoment({
      key,
      newValue: newValueAsMoment,
      oldValue,
      changes,
      content,
    });

    return comparisonFn({ value: newValueAsMoment, target }) || message;
  };
}

export function validateMomentIsSameOrAfter({ getTargetMoment, message }) {
  return validateMoment({
    getTargetMoment,
    message,
    comparisonFn: ({ value, target }) => value.isSameOrAfter(target),
  });
}

export function validateMomentIsAfter({ getTargetMoment, message }) {
  return validateMoment({
    getTargetMoment,
    message,
    comparisonFn: ({ value, target }) => value.isAfter(target),
  });
}

export function validateMomentIsSameOrBefore({ getTargetMoment, message }) {
  return validateMoment({
    getTargetMoment,
    message,
    comparisonFn: ({ value, target }) => value.isSameOrBefore(target),
  });
}
