import UnauthenticatedRoute from 'smile-admin/routes/unauthenticated';
import { isBlank } from '@ember/utils';

export default UnauthenticatedRoute.extend({
  beforeModel(transition) {
    this._super(...arguments);

    // If we don't have the reset password token, nothing to do here
    if (isBlank(transition.to.queryParams.reset_password_token)) {
      transition.send('goLogin');
    }
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('resetPasswordToken', null);
    }
  },
});
