import Model, { hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { reads, equal } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const slugs = {
  free: 'free',
  starter: 'starter-2019-04-15',
  growth: 'growth-2019-04-15',
  pro: 'pro-2019-04-15',
  starterWithOrderLimit: 'starter-2023-04',
  growthWithOrderLimit: 'growth-2023-04',
  plus: 'plus-2023-04',
  enterprise: 'enterprise-2019-04-15',

  legacyGrowth: 'growth',

  shopifyEmployee: 'shopify_employee',
  smileEmployee: 'smile_employee',
  developer: 'developer',
  partner: 'partner',

  wixFree: 'wix-free',
  wixStandard: 'wix-standard',
  wixPremium: 'wix-premium',
};

export default Model.extend({
  slug: attr('string'),
  name: attr('string'),
  interval: attr('string'),
  intervalCount: attr('number'),

  createdAt: attr('date'),
  updatedAt: attr('date'),

  billingSubscriptionTemplateProductPlans: hasMany(
    'billing-subscription-template-product-plan',
    { async: false, inverse: null }
  ),

  hasLegacyGrowthSlug: equal('slug', slugs.legacyGrowth).readOnly(),

  hasFreeSlug: equal('slug', slugs.free).readOnly(),
  hasStarterSlug: equal('slug', slugs.starter).readOnly(),
  hasGrowthSlug: equal('slug', slugs.growth).readOnly(),
  hasProSlug: equal('slug', slugs.pro).readOnly(),
  hasStarterWithOrderLimitSlug: equal(
    'slug',
    slugs.starterWithOrderLimit
  ).readOnly(),
  hasGrowthWithOrderLimitSlug: equal(
    'slug',
    slugs.growthWithOrderLimit
  ).readOnly(),
  hasPlusSlug: equal('slug', slugs.plus).readOnly(),
  hasWixStandardSlug: equal('slug', slugs.wixStandard).readOnly(),
  hasWixPremiumSlug: equal('slug', slugs.wixPremium).readOnly(),
  hasEnterpriseSlug: equal('slug', slugs.enterprise).readOnly(),

  basePlan: reads('baseTemplateProductPlan.newBillingPlan'),
  appsAddOnNewBillingPlan: reads('appsAddOnTemplateProductPlan.newBillingPlan'),

  isMonthly: equal('interval', 'month').readOnly(),
  isQuarterly: equal('interval', 'quarter').readOnly(),
  isYearly: equal('interval', 'year').readOnly(),

  membersAddOnNewBillingPlan: reads(
    'membersAddOnTemplateProductPlan.newBillingPlan'
  ),

  ordersAddOnNewBillingPlan: reads(
    'ordersAddOnTemplateProductPlan.newBillingPlan'
  ),

  baseTemplateProductPlan: computed(
    'billingSubscriptionTemplateProductPlans.@each.isBaseProductPlan',
    function () {
      return this.get('billingSubscriptionTemplateProductPlans').find(
        (item) => item.isBaseProductPlan
      );
    }
  ).readOnly(),

  membersAddOnTemplateProductPlan: computed(
    'billingSubscriptionTemplateProductPlans.@each.isMembersAddOnProductPlan',
    function () {
      return this.get('billingSubscriptionTemplateProductPlans').find(
        (item) => item.isMembersAddOnProductPlan
      );
    }
  ).readOnly(),

  ordersAddOnTemplateProductPlan: computed(
    'billingSubscriptionTemplateProductPlans.@each.isOrdersAddOnProductPlan',
    function () {
      return this.get('billingSubscriptionTemplateProductPlans').find(
        (item) => item.isOrdersAddOnProductPlan
      );
    }
  ).readOnly(),

  appsAddOnTemplateProductPlan: computed(
    'billingSubscriptionTemplateProductPlans.@each.isAppsAddOnProductPlan',
    function () {
      return this.get('billingSubscriptionTemplateProductPlans').find(
        (item) => item.isAppsAddOnProductPlan
      );
    }
  ).readOnly(),

  subscribe: memberAction({
    path: 'subscribe',
    type: 'post',
    after: serializeAndPush,
  }),
});
