import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import LoadingWindowMixin from 'smile-admin/mixins/routes/loading-window';

export default Route.extend(LoadingWindowMixin, {
  session: service(),

  beforeModel() {
    this.session.prohibitAuthentication('home');
    this._super(...arguments);
  },
});
