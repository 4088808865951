import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import moment from 'moment-timezone';

export const states = {
  pending: 'pending',
  completed: 'completed',
  failed: 'failed',
  cancelled: 'cancelled',
  voided: 'voided',
};

export const usageStatuses = {
  unused: 'unused',
  untracked: 'untracked',
  used: 'used',
};

export default Model.extend({
  name: attr('string'),
  image_url: attr('string'),
  instructions_html: attr('string'),
  action_text: attr('string'),
  action_url: attr('string'),
  sourceDescription: attr('string'),
  sourceType: attr('string'),
  code: attr('string'),
  distinctId: attr('number'),
  state: attr('string'),
  usageStatus: attr('string'),
  description: attr('string'),

  createdAt: attr('utc'),
  updated_at: attr('utc'),

  expiresAt: attr('utc'),
  usedAt: attr('utc'),

  customer: belongsTo('customer', { async: false, inverse: null }),
  reward: belongsTo('reward', { async: false, inverse: null }),

  isCompleted: equal('state', states.completed),
  isCancelled: equal('state', states.cancelled),

  isUnused: equal('usageStatus', usageStatuses.unused),
  isUsed: equal('usageStatus', usageStatuses.used),
  isUntracked: equal('usageStatus', usageStatuses.untracked),

  hasExpired: computed('expiresAt', function () {
    return this.expiresAt && this.expiresAt.isBefore(moment());
  }),
});
