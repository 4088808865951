import BillingSubscriptionTemplateData from 'smile-admin/objects/billing-subscription-template-data';
import numeral from 'numeral';
import moment from 'moment-timezone';
import { pluralize } from 'ember-inflector';
import { billingFormatPrice } from 'smile-admin/helpers/billing/format-price';
import {
  discountTypes as couponDiscountTypes,
  durations as couponDurations,
} from 'smile-admin/models/billing-coupon';

export default class BillingSubscriptionData extends BillingSubscriptionTemplateData {
  constructor(billingSubscription) {
    super(null, billingSubscription.account);
    this.billingSubscription = billingSubscription;
  }

  get basePlan() {
    return this.billingSubscription.basePlan;
  }

  get membersPlan() {
    return this.billingSubscription.membersAddOnNewBillingPlan;
  }

  get ordersPlan() {
    return this.billingSubscription.ordersAddOnNewBillingPlan;
  }

  get appsPlan() {
    return this.billingSubscription.appsAddOnNewBillingPlan;
  }

  // A subscription will only have one of these at any given time
  get billingDiscount() {
    return (
      this.billingSubscription.activeBillingDiscount ||
      this.billingSubscription.pendingBillingDiscount
    );
  }

  get monthlyOrdersCredits() {
    return this.account.monthlyOrdersCredits;
  }

  get basePlanMonthlyOrdersAllowance() {
    return this.account.basePlanMonthlyOrdersAllowance;
  }

  get membersSubscriptionItemQuantity() {
    return this.billingSubscription.membersAddOnSubscriptionItem?.quantity || 0;
  }

  get membersPlanMembersCountTotal() {
    return this.membersSubscriptionItemQuantity * this.membersPlanMembersCount;
  }

  get membersCountTotal() {
    return super.membersCountTotal + this.membersPlanMembersCountTotal;
  }

  get membersPlanPriceTotal() {
    return (
      parseInt(this.membersSubscriptionItemQuantity, 10) * this.membersPlanPrice
    );
  }

  get hasExceededMembersLimit() {
    return this.accountMembersCount >= this.membersCountTotal;
  }

  get requiredMonthlyOrdersSubscriptionItemQuantity() {
    // If the subscription is not active yet, we never compute monthly orders overages, since
    // we bill them at the end of the month not upfront (especially applies for billing offers)
    if (!this.billingSubscription.isChargeable) {
      return 0;
    }

    return super.requiredMonthlyOrdersSubscriptionItemQuantity;
  }

  get hasExceededBasePlanMonthlyOrders() {
    return this.accountOrdersCount >= this.monthlyOrdersTotal;
  }

  get numberOfAdditionalOrders() {
    if (!this.hasExceededBasePlanMonthlyOrders) {
      return 0;
    }

    return this.accountOrdersCount - this.monthlyOrdersTotal;
  }

  get supportsOrdersAddOn() {
    return !!this.ordersPlan;
  }

  get supportsMembersBuckets() {
    return !!this.membersPlan;
  }

  get appsSubscriptionItemQuantity() {
    return this.billingSubscription.appsAddOnSubscriptionItem?.quantity || 0;
  }

  get appsPlanAppsCountTotal() {
    return this.appsSubscriptionItemQuantity * this.appsPlanAppsCount;
  }

  get appsCountTotal() {
    return super.appsCountTotal + this.appsPlanAppsCountTotal;
  }

  get accountRemainingAppsCount() {
    return this.appsCountTotal - this.accountAppsCount;
  }

  get appsPlanPriceTotal() {
    return parseInt(this.appsSubscriptionItemQuantity, 10) * this.appsPlanPrice;
  }

  get discountAmountCents() {
    if (!this.billingDiscount) {
      return 0;
    }

    let { billingCoupon } = this.billingDiscount;
    if (billingCoupon.discountType === couponDiscountTypes.fixedAmount) {
      return billingCoupon.amountCentsOff;
    } else if (billingCoupon.discountType === couponDiscountTypes.percentage) {
      return this.priceTotalBeforeDiscount * (billingCoupon.percentOff / 100);
    }

    return 0;
  }

  get friendlyDiscountDurationCount() {
    let { billingDiscount, basePlan } = this;
    if (!billingDiscount) {
      return null;
    }

    let {
      billingCoupon: { durationCount },
    } = billingDiscount;

    if (basePlan.isMonthly) {
      return durationCount;
    } else if (basePlan.isQuarterly) {
      return durationCount / 3;
    } else if (basePlan.isYearly) {
      return durationCount / 12;
    }

    return null;
  }

  get discountDescription() {
    let {
      billingDiscount,
      friendlyDiscountDurationCount,
      basePlan: { interval },
    } = this;

    if (!billingDiscount) {
      return null;
    }

    let value,
      {
        billingCoupon: { discountType, amountCentsOff, percentOff, duration },
        endsAt,
      } = billingDiscount;

    if (discountType === couponDiscountTypes.fixedAmount) {
      // TODO move this out into a helper....seems more presentation than not
      value = billingFormatPrice(amountCentsOff);
    } else {
      value = `${numeral(percentOff).format('0,0[.]00')}%`;
    }

    value = `${value} off`;
    if (endsAt && endsAt.isValid()) {
      value = `${value} until ${moment(endsAt).format('MMMM DD, YYYY')}`;
    } else {
      if (duration === couponDurations.once) {
        value = `${value} once`;
      } else if (duration === couponDurations.forever) {
        value = `${value} forever`;
      } else if (duration === couponDurations.repeating) {
        value = `${value} for ${pluralize(
          friendlyDiscountDurationCount,
          interval
        )}`;
      }
    }

    return value;
  }

  get priceTotalBeforeDiscount() {
    return (
      this.basePlanPrice +
      this.membersPlanPriceTotal +
      this.appsPlanPriceTotal +
      this.ordersPlanPriceTotal
    );
  }

  get priceTotal() {
    return this.priceTotalBeforeDiscount - this.discountAmountCents;
  }

  get priceTotalPerMonth() {
    if (this.basePlan.isMonthly) {
      return this.priceTotal;
    }
    if (this.basePlan.isQuarterly) {
      return this.priceTotal / 4;
    }
    if (this.basePlan.isYearly) {
      return this.priceTotal / 12;
    }

    return null;
  }

  get membersFirstBucketCountMax() {
    return this.basePlanMembersCount + this.creditsMembersCount;
  }

  get membersBucketCountMax() {
    return this.membersCountTotal;
  }

  get membersBucketCountMin() {
    let {
      accountMembersCount,
      membersFirstBucketCountMax,
      membersPlanMembersCount: membersBucketStep,
      membersBucketCountMax,
    } = this;

    if (accountMembersCount <= membersFirstBucketCountMax) {
      return 0;
    }

    return membersBucketCountMax - membersBucketStep;
  }

  get membersBucketUsagePercentage() {
    let { accountMembersCount, membersBucketCountMin, membersBucketCountMax } =
      this;

    // Calculated using simple formula
    // ((account_members_count - bucket_min) * 100) / (bucket_max - bucket_min)
    let percentage =
      ((accountMembersCount - membersBucketCountMin) * 100) /
      (membersBucketCountMax - membersBucketCountMin);
    return percentage;
  }

  get nextBilledAt() {
    return this.billingSubscription.nextBilledAt;
  }
}
