import Model, { belongsTo, attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';
import { computed } from '@ember/object';
import { bool } from '@ember/object/computed';
import moment from 'moment-timezone';

export default Model.extend({
  code: attr('string'),
  consumed_at: attr('utc'),

  starts_at: attr('utc'),
  ends_at: attr('utc'),

  created_at: attr('utc'),
  updated_at: attr('utc'),

  reward: belongsTo('reward', { async: false, inverse: null }),

  hasExpiry: bool('ends_at'),

  isExpired: computed('ends_at', function () {
    if (isBlank(this.get('ends_at'))) {
      return false;
    }

    let now = moment();
    return this.get('ends_at').isBefore(now);
  }),
});
