import setupDeprecationWorkflow from 'ember-cli-deprecation-workflow';

setupDeprecationWorkflow({
  workflow: [
    {
      handler: 'silence',
      matchId: 'ember-data:deprecate-non-strict-relationships',
    },
    {
      handler: 'silence',
      matchId: 'ember-local-storage.initializers.local-storage-adapter',
    },
    { handler: 'silence', matchId: 'ember-polyfills.deprecate-assign' },
    { handler: 'silence', matchId: 'ember-data:deprecate-legacy-imports' },
    { handler: 'silence', matchId: 'template-action' },
    {
      handler: 'silence',
      matchId: 'ember-lifeline-deprecated-context-bound-event-listeners-mixin',
    },
    {
      handler: 'silence',
      matchId: 'ember-lifeline-deprecated-context-bound-tasks-mixin',
    },
    { handler: 'silence', matchId: 'ember-polaris.polaris-stack.class-arg' },
    {
      handler: 'silence',
      matchId: 'ember-lifeline-deprecated-addeventlistener',
    },
    { handler: 'silence', matchId: 'ember-polaris.polaris-icon.class-arg' },
    { handler: 'silence', matchId: 'ember-polaris.polaris-card.class-arg' },
  ],
});
