import moment from 'moment-timezone';

export function initialize(/* application */) {
  // This doesn't seem to do anything at a glance, but it's needed to ensure that
  // moment-timezone has been initialised before anything tries accessing timezone
  // functionality. This is here because we were encountering a timing issue in our
  // `moment-in-account-timezone` transform where upon loading, moment-timezone had
  // not been initialised, resulting in errors when trying to call `.tz()` on the
  // data returned by the back end.
  moment();
}

export default {
  initialize,
};
