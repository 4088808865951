/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { hash } from 'rsvp';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default Mixin.create({
  store: service(),
  daysToRetrieve: 30,

  model() {
    const now = moment();

    let startDate = moment(now)
      .subtract(this.get('daysToRetrieve'), 'd')
      .format('YYYY-MM-DD');
    let endDate = moment(now).format('YYYY-MM-DD');

    this.get('fetchDailyMetricSets').perform({
      startDate,
      endDate,
    });
  },

  fetchDailyMetricSets: task(function* (selectedDateRange) {
    let pointsProgramDailyMetricSets = this.store.query(
      'points-program-daily-metric-set',
      {
        start_date: selectedDateRange.startDate,
        end_date: selectedDateRange.endDate,
      }
    );

    let accountDailyMetricSets = this.store.query('account-daily-metric-set', {
      start_date: selectedDateRange.startDate,
      end_date: selectedDateRange.endDate,
    });

    let metrics = yield hash({
      pointsProgramDailyMetricSets,
      accountDailyMetricSets,
    });

    this.controller.setProperties(metrics);
  }).restartable(),
});
