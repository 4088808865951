/* import __COLOCATED_TEMPLATE__ from './choose-interaction-medium.hbs'; */
import WidePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/wide-modal';
import { readOnly, and } from '@ember/object/computed';
import { dropTask } from 'ember-concurrency';
import { storageFor } from 'ember-local-storage';
import { interactionMediums } from 'smile-admin/models/display-setting';
import { inject as service } from '@ember/service';

export default class ChooseInteractionMediumDisplaySettingModalComponent extends WidePolarisModal {
  classNames = ['choose-interaction-medium-display-setting-modal'];

  @service errorHandler;
  @service sesh;
  @service store;

  interactionMediums = interactionMediums;

  @storageFor('sweet-tooth-session')
  sessionStorage;

  @readOnly('fetchDisplaySetting.lastSuccessful.value')
  displaySetting;

  @and(
    'chooseInteractionMedium.isRunning',
    'displaySetting.hasPromptsInteractionMedium'
  )
  isPromptsChoiceBeingSelected;

  @and(
    'chooseInteractionMedium.isRunning',
    'displaySetting.hasPanelInteractionMedium'
  )
  isPanelChoiceBeingSelected;

  @dropTask
  chooseInteractionMedium = function* chooseInteractionMedium(medium) {
    try {
      let { displaySetting } = this;
      displaySetting.set('interactionMedium', medium);

      yield displaySetting.save();

      // Reset session-storage flag
      this.sessionStorage.set(
        'showChooseDisplayInteractionMediumPostOnboarding',
        false
      );

      this.onCloseModal();
    } catch (err) {
      this.errorHandler.handle(err);
    }
  };

  @dropTask
  fetchDisplaySetting = function* fetchDisplaySetting() {
    try {
      let displaySettings = yield this.store.query('display-setting', {
        account_id: this.sesh.account.id,
      });

      return displaySettings[0];
    } catch (err) {
      this.errorHandler.handle(err);
    }
  };

  init() {
    super.init(...arguments);
    this.fetchDisplaySetting.perform();
  }
}
