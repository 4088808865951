import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { isNone } from '@ember/utils';
import { computed } from '@ember/object';
import { alias, equal, bool, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { memberAction } from 'ember-api-actions';
import moment from 'moment-timezone';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

const signupSources = {
  website: 'smile_website',
  shopify: 'smile_shopify_app',
  bigcommerce: 'smile_bigcommerce_app',
  admin: 'smile_admin',
};

const billingProviders = {
  stripe: 'stripe',
  shopify: 'shopify',
  wix: 'wix',
  manual: 'manual',
};

export const candidateParticipation = {
  none: 'none',
  earn: 'earn',
};

export default Model.extend({
  config: service(),
  sesh: service(),
  rollouts: service('feature-rollouts'),
  billing: service(),

  name: attr('string'),
  is_enabled: attr('boolean'),
  is_available: attr('boolean'),
  url: attr('string'),
  members_count: attr('number'),
  shared_secret: attr('string'),
  signupSource: attr('string'),
  platformType: attr('string'),
  timezoneIana: attr('string'),
  timezoneName: attr('string'),
  isCurrencyCodeEditable: attr('boolean'),
  currencyMultiplier: attr('number'),
  onboardingImprovementsCheckDisplay: attr('boolean'),
  hasPreviouslyPaidBillingSubscription: attr('boolean'),
  hasBillingInfo: attr('boolean'),
  currencyConversionRate: attr('number'),
  startGuideStep: attr('string'),
  lastSeenSmileUiInitRequest: attr('date'),

  created_at: attr('date'),
  updated_at: attr('date'),

  // Settings JSON
  currency_code: attr('string'),
  currencyDisplayType: attr('string'),
  customized_cost_per_click: attr('number'),
  cost_per_click: attr('number'),
  default_order_award_condition: attr('object'),
  default_order_cancel_condition: attr('object'),
  sender_name: attr('string'),
  sender_email: attr('string'),
  billing_email: attr('string'),
  preferredBillingProvider: attr('string'),
  candidateParticipation: attr('string'),
  loadJsSdkAtLaunch: attr('boolean'),
  usesShopifyAppEmbed: attr('boolean'),
  partOfGrowthQualifiedSeptemberOffer: attr('boolean'),

  // Hash of {string => boolean}, eg ["feature_1" => true, "feature_2" => false]
  new_feature_flags: attr('object'),

  featureRollouts: attr('array'),

  usesImprovedMobileLauncher: attr('boolean'),
  usesLauncherLessReferrals: attr('boolean'),
  eligibleForLauncherlessPrograms: attr('boolean'),
  eligibleForReducedFreeBranding: attr('boolean'),

  // TODO: [merged-rules] Remove the flag bellow when we finish migrating all merchants
  usesMergedOrderActivityRules: attr('boolean'),

  /**
   * This is an object of boolean flags indicating which actions
   * a merchant has taken.
   * E.g. styled_loyalty_program
   */
  merchant_behaviour_flags: attr('object'),

  lockedPendingPayment: attr('boolean'),

  currency: belongsTo('currency', { async: false, inverse: null }),
  displaySetting: belongsTo('display-setting', {
    async: false,
    inverse: 'account',
  }),
  onboardingQuizAnswerSet: belongsTo('onboarding-quiz-answer-set', {
    async: false,
    inverse: 'account',
  }),
  billingSubscriptions: hasMany('billing-subscription', {
    async: false,
    inverse: 'account',
  }),
  newIntegrations: hasMany('new-integration', {
    async: false,
    inverse: 'account',
  }),
  reward_programs: hasMany('reward-program', {
    async: false,
    inverse: 'account',
  }),
  usageCredits: hasMany('usage-credit', { async: false, inverse: 'account' }),
  users: hasMany('user', { async: false, inverse: 'accounts' }),
  accountOrderLimit: belongsTo('account-order-limit', {
    async: false,
    inverse: null,
  }),
  dataSet: belongsTo('account-data-set', { async: false, inverse: 'account' }),

  /**
   * Normalized settings for easier coding
   */
  currencyCode: alias('currency.iso_code'),
  currencySymbol: alias('currency.symbol'),

  isCustomPlatformType: equal('platformType', 'custom'),

  canCandidatesEarn: equal(
    'candidateParticipation',
    candidateParticipation.earn
  ),

  hasBillingSubscriptionOffer: bool('billingSubscriptionOffer').readOnly(),

  isSignupFromWebsite: equal('signupSource', signupSources.website).readOnly(),
  isSignupFromAdmin: equal('signupSource', signupSources.admin).readOnly(),
  isSignupFromShopify: equal('signupSource', signupSources.shopify).readOnly(),

  isSignupFromBigcommerce: equal(
    'signupSource',
    signupSources.bigcommerce
  ).readOnly(),

  showDelightfulEmailsMigratedCard: alias(
    'merchant_behaviour_flags.show_delightful_emails_migrated_card'
  ),

  showGuestReferralsEnablementCard: alias(
    'merchant_behaviour_flags.show_guest_referrals_enablement_card'
  ),

  showHtmlEmailUpdatesCard: alias(
    'merchant_behaviour_flags.show_html_email_updates_card'
  ),

  showGuestReferralsDisabledNotice: alias(
    'merchant_behaviour_flags.show_guest_referrals_disabled_notice'
  ),

  hasRequestedChurnCredit: readOnly(
    'merchant_behaviour_flags.has_requested_churn_credit'
  ),

  hasSeenBrandingProductTour: alias(
    'merchant_behaviour_flags.has_seen_branding_product_tour'
  ),

  hasSeenHomePageProductTour: alias(
    'merchant_behaviour_flags.has_seen_home_page_product_tour'
  ),

  reviewRating: alias('merchant_behaviour_flags.review_rating'),

  isQuizComplete: computed('onboardingQuizAnswerSet.isComplete', function () {
    // Anyone that doesn't have an associated `onboarding-quiz-answer-set` record
    // is on older account and we consider that it completed the quiz
    if (isNone(this.onboardingQuizAnswerSet)) {
      return true;
    }

    return this.onboardingQuizAnswerSet.isComplete;
  }).readOnly(),

  totalMonthlyOrdersWhenLastEvaluated: computed(
    'accountOrderLimit.totalMonthlyOrdersWhenLastEvaluated',
    function () {
      // This method is also used to define if we should disable
      // the upgrade button from the Starter plan, so we add this condition
      // to handle fresh install cases where we don't have an
      // accountOrderLimit.totalMonthlyOrdersWhenLastEvaluated yet
      if (!this.get('accountOrderLimit') || this.isInOrderLimitTrialPeriod) {
        return this.sesh.platformIntegration?.totalOrders28dBeforeInstall || 0;
      }

      return (
        this.get('accountOrderLimit.totalMonthlyOrdersWhenLastEvaluated') || 0
      );
    }
  ).readOnly(),

  get totalOrders28d() {
    return this.dataSet?.totalOrders28d || 0;
  },

  ordersCountToPlanRecommendation: computed(
    'daysSinceSignup',
    'totalMonthlyOrdersWhenLastEvaluated',
    'totalOrders28d',
    'this.accountOrderLimit.orderLimitLastEvaluatedAt',
    function () {
      // If the account is less than 28 days old, it will not have an
      // accountOrderLimit.totalMonthlyOrdersWhenLastEvaluated yet, so we should
      // use the max between the totalOrders28d and the totalOrders28dBeforeInstall
      // to recommended the correct plan to this merchant.
      if (this.daysSinceSignup <= 28) {
        return Math.max(
          this.totalOrders28d,
          this.sesh.platformIntegration?.totalOrders28dBeforeInstall || 0
        );
      }

      if (moment().isAfter(this.accountOrderLimit?.orderLimitLastEvaluatedAt)) {
        return Math.max(
          this.totalOrders28d,
          this.totalMonthlyOrdersWhenLastEvaluated
        );
      }

      return this.totalOrders28d;
    }
  ).readOnly(),

  billingSubscriptionOffer: computed(
    'billingSubscriptions.@each.isOffered',
    function () {
      return this.get('billingSubscriptions').find(
        (subscription) => subscription.isOffered
      );
    }
  ).readOnly(),

  chargeableSubscription: computed(
    'billingSubscriptions.@each.isChargeable',
    function () {
      return this.get('billingSubscriptions').find(
        (subscription) => subscription.isChargeable
      );
    }
  ).readOnly(),

  statusText: computed('is_enabled', function () {
    return this.get('is_enabled') ? 'live' : 'paused';
  }),

  statusClass: computed('is_enabled', function () {
    return this.get('is_enabled') ? 'green' : 'yellow';
  }),

  signedUpLessThan24hAgo: computed('created_at', function () {
    return moment().subtract(1, 'days').isBefore(this.get('created_at'));
  }),

  daysSinceSignup: computed('created_at', function () {
    return moment().diff(this.created_at, 'days');
  }).readOnly(),

  shouldSeeDataPondOrderLimit: computed('createdAt', function () {
    if (this.sesh.hasWixPlatform) {
      return false;
    }

    if (this.get('accountOrderLimit')) {
      return true;
    }

    const orderLimitRolloutDate = moment('2023-03-01', 'YYYY-MM-DD');
    const createdAt = moment(this.get('created_at'));
    return createdAt.isAfter(orderLimitRolloutDate);
  }).readOnly(),

  isInOrderLimitTrialPeriod: computed(
    'accountOrderLimit.isInOrderLimitTrialPeriod',
    'chargeableSubscription.{isFree,isLegacyFree,isStarterWithOrderLimit}',
    function () {
      return (
        (this.get('accountOrderLimit.isInOrderLimitTrialPeriod') ||
          (!this.get('accountOrderLimit') &&
            this.shouldSeeDataPondOrderLimit)) &&
        (this.chargeableSubscription.isFree ||
          this.chargeableSubscription.isLegacyFree ||
          this.chargeableSubscription.isStarterWithOrderLimit)
      );
    }
  ).readOnly(),

  isInOrderLimitEvaluationPeriod: computed(
    'accountOrderLimit.isInOrderLimitEvaluationPeriod',
    'chargeableSubscription.{isFree,isLegacyFree,isStarterWithOrderLimit}',
    function () {
      return (
        this.get('accountOrderLimit.isInOrderLimitEvaluationPeriod') &&
        (this.chargeableSubscription.isFree ||
          this.chargeableSubscription.isLegacyFree ||
          this.chargeableSubscription.isStarterWithOrderLimit)
      );
    }
  ).readOnly(),

  isInOrderLimitGracePeriod: computed(
    'accountOrderLimit.isInOrderLimitGracePeriod',
    'chargeableSubscription.{isFree,isLegacyFree,isStarterWithOrderLimit}',
    function () {
      if (this.hasJustChangedToStarter) {
        return (
          this.get('accountOrderLimit.isInOrderLimitGracePeriod') &&
          this.totalMonthlyOrdersWhenLastEvaluated > this.monthlyOrdersTotal
        );
      }

      return (
        this.get('accountOrderLimit.isInOrderLimitGracePeriod') &&
        (this.chargeableSubscription.isFree ||
          this.chargeableSubscription.isLegacyFree ||
          this.chargeableSubscription.isStarterWithOrderLimit)
      );
    }
  ).readOnly(),

  isInOrderLimitUpgradePeriod: computed(
    'totalMonthlyOrdersWhenLastEvaluated',
    'monthlyOrdersTotal',
    'accountOrderLimit.{isInOrderLimitUpgradePeriod,billingSubscriptionId,isPastOrderLimitGracePeriod}',
    'chargeableSubscription.{isFree,isLegacyFree,isStarterWithOrderLimit,id}',
    function () {
      if (this.hasJustChangedToStarter) {
        return (
          this.get('accountOrderLimit.isPastOrderLimitGracePeriod') &&
          this.totalMonthlyOrdersWhenLastEvaluated > this.monthlyOrdersTotal
        );
      }

      return (
        this.get('accountOrderLimit.isInOrderLimitUpgradePeriod') &&
        (this.chargeableSubscription.isFree ||
          this.chargeableSubscription.isLegacyFree ||
          this.chargeableSubscription.isWixFree ||
          this.chargeableSubscription.isStarterWithOrderLimit)
      );
    }
  ).readOnly(),

  shouldSeeLockoutScreen: computed(
    'isInOrderLimitUpgradePeriod',
    'sesh.bypassUpgradeScreen',
    function () {
      if (this.sesh.bypassUpgradeScreen) {
        return false;
      }

      if (this.sesh.hasWixPlatform) {
        return false;
      }

      if (!this.isQuizComplete) {
        return false;
      }

      return this.isInOrderLimitUpgradePeriod;
    }
  ).readOnly(),

  basePlanMonthlyOrdersAllowance: alias(
    'chargeableSubscription.basePlan.billingPlanUsageAllowance.monthlyOrders'
  ),

  monthlyOrdersCredits: computed('usageCredits', function () {
    return this.usageCredits
      .map((credit) => credit.monthlyOrders)
      .reduce((total, count) => total + count, 0);
  }).readOnly(),

  monthlyOrdersTotal: computed(
    'basePlanMonthlyOrdersAllowance',
    'monthlyOrdersCredits',
    function () {
      return this.basePlanMonthlyOrdersAllowance + this.monthlyOrdersCredits;
    }
  ).readOnly(),

  orderLimitUsagePercentage: computed(
    'totalMonthlyOrdersWhenLastEvaluated',
    'totalOrders28d',
    'monthlyOrdersTotal',
    'chargeableSubscription.{isFree,isLegacyFree,isStarterWithOrderLimit}',
    function () {
      if (
        this.chargeableSubscription.isFree ||
        this.chargeableSubscription.isLegacyFree ||
        this.chargeableSubscription.isStarterWithOrderLimit
      ) {
        return (
          (this.totalMonthlyOrdersWhenLastEvaluated * 100) /
          this.monthlyOrdersTotal
        );
      }

      return (this.totalOrders28d * 100) / this.monthlyOrdersTotal;
    }
  ).readOnly(),

  hasJustChangedToStarter: computed(
    'chargeableSubscription.{isStarterWithOrderLimit,id}',
    'accountOrderLimit.billingSubscriptionId',
    function () {
      return (
        this.chargeableSubscription.id !=
          this.get('accountOrderLimit.billingSubscriptionId') &&
        this.chargeableSubscription.isStarterWithOrderLimit
      );
    }
  ).readOnly(),

  hasStripePreferredBillingProvider: equal(
    'preferredBillingProvider',
    billingProviders.stripe
  ).readOnly(),
  hasShopifyPreferredBillingProvider: equal(
    'preferredBillingProvider',
    billingProviders.shopify
  ).readOnly(),
  hasWixPreferredBillingProvider: equal(
    'preferredBillingProvider',
    billingProviders.wix
  ).readOnly(),
  hasManualPreferredBillingProvider: equal(
    'preferredBillingProvider',
    billingProviders.manual
  ).readOnly(),

  enable: memberAction({ path: 'enable' }),
  disable: memberAction({ path: 'disable' }),
  updateStripePaymentSource: memberAction({
    path: 'update_stripe_payment_source',
    after: serializeAndPush,
  }),
  startCustomerImport: memberAction({
    path: 'start_customer_import',
    type: 'post',
    after: serializeAndPush,
  }),
  startCustomerPointsImport: memberAction({
    path: 'start_customer_points_import',
    type: 'post',
    after: serializeAndPush,
  }),

  getPreviewCustomer: memberAction({ path: 'customer_preview', type: 'get' }),

  acceptChurnCreditOffer: memberAction({
    path: 'accept_churn_credit_offer',
    after: serializeAndPush,
  }),

  updateCandidateParticipationStatus: memberAction({
    path: 'update_candidate_participation_status',
    after: serializeAndPush,
  }),
});
