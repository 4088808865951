import Route from '@ember/routing/route';
import { reject } from 'rsvp';
import { computed } from '@ember/object';
import { set } from '@ember/object';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { allowedRoutesForRestrictedAccessOverLimit } from '../router';
import { storageFor } from 'ember-local-storage';

export default Route.extend({
  router: service('router'),
  billing: service(),
  errorHandler: service(),
  sesh: service(),
  store: service(),

  sessionStorage: storageFor('sweet-tooth-session'),

  queryParams: {
    chargeId: {
      replace: true,
    },
    bypassUpgradeScreen: {
      replace: true,
    },
  },

  bigcommerceProductQueryParams: computed(
    'sesh.platformIntegration.id',
    function () {
      return {
        new_integration_id: this.sesh.get('platformIntegration.id'),
      };
    }
  ).readOnly(),

  beforeModel(transition) {
    this._super(...arguments);

    this.sesh.attemptUpgradeScreenBypass(
      transition.to.queryParams?.bypass_upgrade_screen === 'true'
    );
  },

  setupController(controller) {
    this._super(...arguments);

    let { chargeId } = controller;
    // If we have a chargeId (meaning we're redirected back from Shopify Payments) trigger finishing
    // the Shopify payment flow
    if (chargeId) {
      this.billing.processShopifyPaymentsCallback.perform(chargeId);

      // We reset the query param right here (as early as possible), to avoid re-running the task in
      // case merchant refreshes or something
      schedule('afterRender', this, function () {
        set(controller, 'chargeId', null);
      });
    }
  },

  redirect(model, transition) {
    // If the quiz is not complete, redirect there
    if (!this.sesh.isOnboardingComplete) {
      return this.router.transitionTo('start');
    }

    if (this.sessionStorage.get('lockedPendingPayment')) {
      return this.router.replaceWith('restricted-access');
    }

    if (
      this.sessionStorage.get('shouldSeeLockoutScreen') &&
      !allowedRoutesForRestrictedAccessOverLimit.includes(transition.targetName)
    ) {
      return this.router.replaceWith('restricted-access-over-limit');
    }
  },

  activate() {
    this._super(...arguments);

    this.send('enableNav');
    this.send('enableTopBar');
  },

  actions: {
    /**
     * Fetches Shopify collections
     * @return {RSVP.Promise}
     */
    fetchShopifyCollections() {
      return this.store.findAll('shopify-collection', { reload: true });
    },

    // Remove this once all Bigcommerce merchants use the new rewards products.
    fetchBigcommerceProducts(searchTerm) {
      let queryParams = {
        name: searchTerm,
        ...this.bigcommerceProductQueryParams,
      };

      return this.store
        .query('bigcommerce-product', queryParams)
        .catch((err) => {
          this.errorHandler.handle(err);
          return reject(err);
        });
    },

    // Remove this once all Bigcommerce merchants use the new rewards products.
    fetchBigcommerceProduct(bigcommerceProductId) {
      return this.store
        .findRecord('bigcommerce-product', bigcommerceProductId, {
          adapterOptions: this.get('bigcommerceProductQueryParams'),
        })
        .catch((err) => {
          this.errorHandler.handle(err);
          return reject(err);
        });
    },
  },
});
