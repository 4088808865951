import Transform from '@ember-data/serializer/transform';
import moment from 'moment-timezone';

export default class MomentTransform extends Transform {
  serialize(deserialized) {
    return deserialized ? deserialized.toDate() : null;
  }

  deserialize(serialized) {
    return serialized ? moment(serialized) : serialized;
  }
}
