import Helper from '@ember/component/helper';
import { underscore } from '@ember/string';
import { typeOf } from '@ember/utils';
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';

export default Helper.extend({
  router: service(),
  metrics: service(),

  compute([action], eventData) {
    assert('Action must be of type function', typeOf(action) === 'function');

    return (...invocationArgs) => {
      let eventParams = {};

      Object.keys(eventData).forEach((key) => {
        eventParams[underscore(key)] = eventData[key];
      });

      // This was tracking things in Mixpanel, commenting out actual tracking only for now
      // this.get('metrics').trackEvent(eventParams);
      return action(...invocationArgs);
    };
  },
});
