import Model, { attr, belongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import addDefaultAndCustomizedFieldsToModel from 'smile-admin/utils/add-default-and-customized-fields-to-model';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

const customizableAttrs = addDefaultAndCustomizedFieldsToModel({
  emailHtmlTemplate: attr('string'),
  subjectTemplate: attr('string'),
  preheaderTemplate: attr('string'),
  titleTemplate: attr('string'),
  bodyTemplate: attr('string'),
  ctaTemplate: attr('string'),
});

export const types = {
  birthday: 'birthday',
  pointsEarned: 'points_earned',
  pointsSpent: 'points_spent',
  referralOffer: 'referral_offer',
  referralAdvocateRewarded: 'referral_advocate_rewarded',
  referralFriendRewarded: 'referral_friend_rewarded',
  referralSharingLink: 'referral_sharing_link',
  newPointsExpiryWarning: 'new_points_expiry_warning',
  newPointsExpiryLastChance: 'new_points_expiry_last_chance',
  rewardFulfillmentExpiryReminder: 'reward_fulfillment_expiry_reminder',
  rewardFulfillmentUnusedReminder: 'reward_fulfillment_unused_reminder',
  // TODO tier-retained-notification: add icon, variables etc. here as per vip_tier_achieved.
  vipTierAchieved: 'vip_tier_achieved',
  vipTierRetained: 'vip_tier_retained',
  loginForUnknownCustomer: 'login_for_unknown_customer',
  loginForKnownCustomer: 'login_for_known_customer',
};

export const typeGroups = {
  other: 'otherEmails',
  points: 'pointsEmails',
  referrals: 'referralsEmails',
  milestone_vip: 'vipEmails',
};

export const promotionTypes = [types.referralSharingLink];

export const cannotEditBodyTemplate = [
  types.newPointsExpiryWarning,
  types.newPointsExpiryLastChance,
  types.rewardFulfillmentExpiryReminder,
];

export const cannotEditCtaTemplate = [
  types.birthday,
  types.pointsEarned,
  types.newPointsExpiryWarning,
  types.newPointsExpiryLastChance,
];

export const v2EmailsWithDynamicCta = [];

export const notificationIconMap = {
  points_earned: 'smile/notification-points-earned',
  birthday: 'smile/notification-points-birthday',
  referral_friend_rewarded: 'smile/notification-referral-completed',
  referral_advocate_rewarded: 'smile/notification-referral-completed',
  referral_offer: 'smile/plane',
  vip_tier_achieved: 'smile/notification-vip-tier-status',
  points_spent: 'smile/gift-box',
  new_points_expiry_warning: 'smile/notification-points-expiry',
  new_points_expiry_last_chance: 'smile/notification-points-expiry',
  reward_fulfillment_expiry_reminder: 'smile/notification-points-expiry',
  reward_fulfillment_unused_reminder: 'smile/gift-box',
  login_for_unknown_customer: 'member-plus-bold',
  login_for_known_customer: 'link',
};

// Required variables per field and per notification type.
// e.g. requiredVariablesMap[field][type];
export const requiredVariablesMap = {
  body: {
    points_earned: [
      {
        value: '{{reward_fulfillment.source_description}}',
        label: 'Source description',
      },
      { value: '{{reward_fulfillment.name}}', label: 'The reward name' },
    ],
    points_spent: [
      { value: '{{reward_fulfillment.name}}', label: 'The reward name' },
      {
        value: '{{points_purchase.points_spent_formatted}}',
        label: 'Redemption amount with currency name',
      },
    ],
    referral_offer: [
      { value: '{{friend_reward.name}}', label: 'The reward name' },
    ],
    referral_friend_rewarded: [
      { value: '{{reward_fulfillment.name}}', label: 'The reward name' },
    ],
    referral_advocate_rewarded: [
      { value: '{{reward_fulfillment.name}}', label: 'The reward name' },
    ],
  },
  title: {
    vip_tier_achieved: [
      {
        value: '{{customer.vip_tier.name}}',
        label: "The name of the customer's tier",
      },
    ],
  },
};

export const headerPlaceholderImage =
  '/assets/images/customer-notification/default-form/header.png';

export default Model.extend(customizableAttrs, {
  type: attr('string'),
  name: attr('string'),
  isEnabled: attr('boolean'),
  isDefault: attr('boolean'),
  isCustomizable: attr('boolean'),
  imageUrl: attr('string'),
  imageTempS3Key: attr('string'),
  emailBodyTitleText: attr('string'),
  emailBodyDescriptionText: attr('string'),
  deliveriesCount: attr('number'),
  usesCustomizedTemplate: attr('boolean'),
  defaultSortOrder: attr('number'),

  publicEmailTemplateVariables: attr('object'),
  privateEmailTemplateVariables: attr('object'),
  previewData: attr('ember-object', { camelCase: false }),

  createdAt: attr('utc'),
  updatedAt: attr('utc'),

  account: belongsTo('account', { async: false, inverse: null }),
  reward_program: belongsTo('reward-program', { async: false, inverse: null }),
  pointsExpiryReminder: belongsTo('points-expiry-reminder', {
    async: false,
    inverse: 'customer_notification',
  }),
  customer_notification_definition: belongsTo(
    'customer-notification-definition',
    { async: false, inverse: null }
  ),

  getPreviewData: memberAction({
    path: 'preview_data',
    type: 'get',
  }),
  deliverTest: memberAction({
    path: 'deliver_test',
    type: 'post',
  }),
  resetCustomizations: memberAction({
    path: 'reset_customizations',
    after: serializeAndPush,
  }),
  removeImage: memberAction({
    path: 'remove_image',
    after: serializeAndPush,
  }),
});
