/* import __COLOCATED_TEMPLATE__ from './confirm-action.hbs'; */
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import BasePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/base';

export default BasePolarisModal.extend({
  classNames: ['polaris-modals-confirm-action-component'],

  title: readOnly('modalParams.title'),
  body: readOnly('modalParams.body'),
  cancelButtonText: readOnly('modalParams.cancelButtonText'),
  confirmButtonText: readOnly('modalParams.confirmButtonText'),
  confirmButtonTooltipText: readOnly('modalParams.confirmButtonTooltipText'),
  confirmButtonDisabled: readOnly('modalParams.confirmButtonDisabled'),
  isActionRunning: readOnly('modalParams.isActionRunning'),
  destructive: readOnly('modalParams.destructive'),

  isConfirmRunning: computed(
    'isActionRunning',
    'confirm.isRunning',
    function () {
      if (isPresent(this.isActionRunning)) {
        return this.isActionRunning;
      } else {
        return this.get('confirm.isRunning');
      }
    }
  ),
});
