/* import __COLOCATED_TEMPLATE__ from './upgrade.hbs'; */
import WidePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/wide-modal';
import { computed } from '@ember/object';
import { readOnly, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default WidePolarisModal.extend({
  billing: service(),
  metrics: service(),
  router: service(),
  sesh: service(),
  featureRollouts: service(),

  isLoading: readOnly('modalParams.isLoading'),
  subscriptionTemplate: readOnly('modalParams.subscriptionTemplate'),
  yearlySubscriptionTemplate: readOnly(
    'modalParams.yearlySubscriptionTemplate'
  ),

  showYearlyPricing: computed(
    'sesh.account.chargeableSubscription.basePlan.{isMonthly,isYearly}',
    'sesh.account.chargeableSubscription.{isWixStandard,isWixPremium}',
    'billing.supportsAnnualSubscriptions',
    function () {
      let {
        basePlan: { isMonthly, isYearly },
        isWixStandard,
        isWixPremium,
      } = this.sesh.account.chargeableSubscription;

      // Special cases for Wix to comply with their internal upgrade policy:
      if (isWixStandard || (isWixPremium && isMonthly)) {
        return true;
      }

      return isYearly && this.billing.supportsAnnualSubscriptions;
    }
  ),

  // TODO: remove this when we no longer display the upgrade modal on the billing page.
  hideViewMorePlansLink: equal(
    'router.currentRouteName',
    'settings.billing'
  ).readOnly(),

  showYearlyPricingToggle: computed(
    'billing.supportsAnnualSubscriptions',
    'subscriptionTemplate.hasEnterpriseSlug',
    'sesh.account.chargeableSubscription',
    function () {
      if (!this.featureRollouts.get('paidPlansWithYearlySubscriptions')) {
        return false;
      }

      // Wix Premium - monthly will show annual pricing by default, and
      // Wix Premium - yearly won't see the modal. So just hide for Premium
      // altogether.
      if (this.sesh.account.chargeableSubscription.isWixPremium) {
        return false;
      }

      return (
        this.billing.supportsAnnualSubscriptions &&
        !this.subscriptionTemplate.hasEnterpriseSlug
      );
    }
  ),

  init() {
    this._super(...arguments);

    this.metrics.trackEvent('GoogleTagManager', {
      event: 'Billing - upgrade modal viewed',
    });
  },

  actions: {
    trackAndCloseModal() {
      if (this.confirm.isRunning) {
        return;
      }

      this.billing.trackEvent(
        `Billing - ${this.subscriptionTemplate.name} modal dismissed`
      );
      this.send('closeModal');
    },

    contactSales() {
      const { name } = this.subscriptionTemplate;
      this.billing.trackEvent(`Billing - ${name} modal contact button clicked`);
      this.billing.contactSales(
        `I’m looking for more info on the ${name} plan.`
      );
    },
  },
});
