import MetricsService from 'ember-metrics/services/metrics';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { storageFor } from 'ember-local-storage';

/**
 * Steps for debugging GTM
 *
 * - uncomment `GOOGLE_TAG_MANAGER_CONTAINER_ID` & `GOOGLE_TAG_MANAGER_ENV_SCRIPT` env variables in `.env`
 * - restart the app
 * - in [GTM](https://tagmanager.google.com/#/container/accounts/909317891/containers/6941893/workspaces/1000093) click
 *   `Preview` at the top-right of the page
 * - enter URL of your ngrok app URL
 * - also, make sure browser extensions are not blocking scripts
 */
export default class SmileMetricsService extends MetricsService {
  @service router;
  @service sesh;

  @storageFor('sweet-tooth-session')
  sessionStorage;

  activateAdapters() {
    // The `metrics` service has an `enabled` property that acts pretty much as
    // a switch on whether tracking should be enabled or not. It uses the user
    // browser's [doNotTrack
    // settings](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack),
    // however, this is deprecated and should not be used anymore. We're
    // overwriting to make sure it is always enabled.
    //
    // NOTE we're doing the overwriting here, because if we do it on the main
    // class it will still not work correctly since initialization is done in
    // the service's `constructor` which is before our overwritting will come
    // into effect. So instead, we do it here which the last thing called in the
    // constructor and first thing we care about.
    //
    // For more see
    // https://github.com/adopted-ember-addons/ember-metrics/blob/v1.5.2/addon/services/metrics.js#L40
    this.enabled = true;

    super.activateAdapters(...arguments);
  }

  trackEvent(serviceName, eventData = {}, { shouldLogForAdmins = false } = {}) {
    let isAdmin = this.get('sessionStorage.isAdmin');
    if (isAdmin) {
      if (!shouldLogForAdmins) {
        return;
      }

      eventData = {
        ...eventData,
        is_admin: true,
      };
    }

    if (isBlank(this.get('sesh.account.id'))) {
      // Dont track events for un-authenticated accounts
      // This will avoid tracking page views for shopify oauth connect
      return;
    }

    let eventParams = {
      page_path: this.get('router.currentURL'),
      ...eventData,
    };

    super.trackEvent(serviceName, eventParams);
  }

  identify() {
    if (this.get('sessionStorage.isAdmin')) {
      return;
    }

    super.identify(...arguments);
  }
}
