import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  iconTempS3Key: attr('string'),
  iconUrl: attr('string'),
  showLinksToWalletFromProgram: attr('boolean'),
  excludedAt: attr('date'),

  account: belongsTo('account', { async: false, inverse: null }),
});
