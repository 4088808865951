import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default Route.extend({
  session: service(),
  sessionStorage: storageFor('sweet-tooth-session'),

  beforeModel() {
    this._super(...arguments);

    this.sessionStorage.set('isAdmin', false);
    this.session.invalidate();
  },
});
