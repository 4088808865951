/* import __COLOCATED_TEMPLATE__ from './restricted-access-over-limit.hbs'; */
import Component from '@glimmer/component';
import { all, dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import { storageFor } from 'ember-local-storage';

export default class RestrictedAccessOverLimit extends Component {
  @service sesh;
  @service errorHandler;

  @storageFor('sweet-tooth-session') sessionStorage;

  @alias('sessionStorage.hasDismissedRestrictedAccessOverLimitBanner')
  hasDismissedRestrictedAccessOverLimitBanner;

  showTurnOffProgramModal = false;

  get totalEarners() {
    return this.sesh.account.dataSet?.totalEarners;
  }

  get showOrderLimitInformationBanner() {
    const orderLimitReleaseDate = moment('2022-04-06', 'YYYY-MM-DD');
    const accountInstalledPriorToOrderLimitRelease = moment(
      this.sesh.account.created_at
    ).isBefore(orderLimitReleaseDate);

    return (
      accountInstalledPriorToOrderLimitRelease &&
      !this.hasDismissedRestrictedAccessOverLimitBanner
    );
  }

  @dropTask
  *disableRewardPrograms() {
    try {
      yield all(
        this.sesh.rewardPrograms
          .filter((rewardProgram) => rewardProgram.is_enabled)
          .map((enabledRewardProgram) => enabledRewardProgram.disable())
      );
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  @action
  dismissBanner() {
    this.hasDismissedRestrictedAccessOverLimitBanner = true;
  }
}
