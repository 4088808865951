import { default as SmileAjaxService } from '@smile-io/ember-smile-core/services/ajax';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default class AjaxService extends SmileAjaxService {
  @service config;
  @service session;
  @service shopify;

  @storageFor('sweet-tooth-session')
  sessionStorage;

  @computed('config.apiHost')
  get host() {
    return this.config.get('apiHost');
  }

  @computed(
    'session.data.authenticated.authentication_token',
    'sessionStorage.isAdmin'
  )
  get headers() {
    let headers = super.headers;

    if (this.get('sessionStorage.isAdmin')) {
      headers['X-SMILE-IS-ADMIN'] = 'true';
    }

    if (
      this.shopify.isEmbedded &&
      this.session.data.authenticated.authentication_token
    ) {
      headers['Authorization'] =
        `Bearer ${this.session.data.authenticated.authentication_token}`;
    }

    return headers;
  }

  postJson(url, data = {}) {
    return this.post(url, {
      beforeSend(xhr, settings) {
        xhr.setRequestHeader('Content-Type', settings.accepts.json);
      },
      data: JSON.stringify(data),
    });
  }
}
