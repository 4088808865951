import { inject as service } from '@ember/service';

export default class AuthHandler {
  @service session;

  request(context, next) {
    const headers = new Headers(context.request.headers);
    headers.append(
      'Authorization',
      `Bearer ${this.session.data.authenticated.authentication_token}`
    );

    return next({ ...context.request, headers });
  }
}
