import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),

  beforeModel(transition) {
    this._super(...arguments);

    // If we are already authenticated
    //  - abort transition (no need to continue here until we reload the page)
    //  - invalidate the session
    //  - reload page to still auto-login, otherwise default behaviour for
    //    `ember-simple-auth` is to redirect to login page
    // NOTE: Technically, we can just re-authenticate even if the session is
    // authenticated, but that breaks ember-simple-auth and causes `sessionAuthenticated`
    // event to not be triggered, resulting in having a tab not refresh if in a different
    // tab we auto-login into a different account (likely what causes the random 403's in
    // Rollbar - when our team signs into various accounts from admin)
    if (this.session.isAuthenticated) {
      transition.abort();
      return this.session
        .invalidate()
        .then(() => transition.send('reloadPage'));
    }
  },

  setupController(controller) {
    this._super(...arguments);
    controller.autoLogin.perform();
  },
});
