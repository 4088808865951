/* import __COLOCATED_TEMPLATE__ from './show-nudges.hbs'; */
import Component from '@ember/component';
import { not } from '@ember/object/computed';
import { storageFor } from 'ember-local-storage';

export default Component.extend({
  sessionStorage: storageFor('sweet-tooth-session'),

  shouldRenderNudgesCard: not('sessionStorage.dismissedNudgesCard'),

  actions: {
    dismiss() {
      let dismiss = this.onDismiss;

      if (dismiss) {
        dismiss('dismissedNudgesCard');
      }
    },
  },
});
