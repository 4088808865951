import Route from '@ember/routing/route';
import LoadingWindowMixin from 'smile-admin/mixins/routes/loading-window';

/**
 * Catch all route for Smile applications
 *
 * Usage:
 *
 *    Add catch-all route entry at the end of `router.js`
 *
 *      Catch all route
 *      this.route('error-404', { path: '/*wildcard' });
 *
 * TODO automatically add the catch-all route?
 */
export default class Error404Route extends Route.extend(LoadingWindowMixin) {
  activate() {
    super.activate(...arguments);

    this.send('disableNav');
    this.send('disableTopBar');
  }
}
