import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import LoadingWindowMixin from 'smile-admin/mixins/routes/loading-window';
import { storageFor } from 'ember-local-storage';

/**
 * This is where we setup the signed in user and their accounts
 * Any initial data we need should be preloaded here
 * eg. Accounts, Channels, etc
 */
export default class AuthenticatedRoute extends Route.extend(
  LoadingWindowMixin
) {
  @service session;
  @service shopify;
  @service sesh;

  @storageFor('sweet-tooth-session') sessionStorage;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    this.session.requireAuthentication(
      transition,
      this.shopify.isEmbedded ? 'auth.shopify' : 'auth.login'
    );

    const setupSessionPromise = this.sesh.setupSession.perform();

    // We do not return setupSessionPromise if `showStaticCardsOnHomePage` is true
    // to avoid blocking rendering until the promise is done.
    if (this.shopify.showStaticCardsOnHomePage) {
      return;
    }

    return setupSessionPromise;
  }
}
