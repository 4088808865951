import { capitalize } from '@ember/string';
import { warn } from '@ember/debug';
import moment from 'moment-timezone';

/**
 * Takes a jsDate with any time zone data and returns
 * a new moment object of the _same_ date but in whatever
 * default timezone moment is set to.
 */
export function newMomentObjectInMomentDefaultZone(jsDate) {
  return moment()
    .year(jsDate.getFullYear())
    .month(jsDate.getMonth())
    .date(jsDate.getDate())
    .hour(jsDate.getHours());
}

/**
 * Takes a date provided by the API in the following format:
 *
 *  2017-12-01T00:00:00+00:00
 *  2017-12-01T00:00:00Z
 *
 * and creates a date object out of it using only the year,
 * month, date, and hours values. Disregards hours and timezone.
 */
export function newDateObjectInDefaultZone(utcFormattedDateString) {
  if (!isUtcFormattedDateString(utcFormattedDateString)) {
    warn('wrong date format passed to `newDateObjectInDefaultZone`', {
      id: 'ember-debug.wrong-date-format',
    });
    return;
  }

  let [year, month, date] = utcFormattedDateString.split('T')[0].split('-');
  let hour = utcFormattedDateString.split('T').pop().split(':')[0];

  // JS Date months are 0-based, whereas
  // the UTC format displays as 1-based.
  month = month - 1;

  return new Date(year, month, date, hour);
}

/**
 * Returns `true` if a passed-in date string matches the
 * following formats:
 *
 *  2017-12-01T00:00:00+00:00
 *  2017-12-01T00:00:00Z
 */
export function isUtcFormattedDateString(dateString) {
  let utcTimeRegex =
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}((\+|-)\d{2}:\d{2}|Z)/g;
  return dateString.match(utcTimeRegex);
}

/**
 * Returns a moment instance set to 00:00:00 UTC on the date of the passed-in instance.
 */
export function startOfDayInUtc(momentInstance) {
  return momentInstance.clone().startOf('day').utc(true);
}

/**
 * Returns a moment instance set to 23:59:59 UTC on the date of the passed-in instance.
 */
export function endOfDayInUtc(momentInstance) {
  return momentInstance.clone().endOf('day').utc(true);
}

/**
 * Calculates the difference between two times to the nearest time unit.
 */
export function diffInUnits(start, end, units) {
  return Math.round(
    moment.duration(end.diff(start))[`as${capitalize(units)}`]()
  );
}
