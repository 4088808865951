import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'smile-admin/config/environment';
import { setBuildURLConfig } from '@ember-data/request-utils';
import './deprecation-workflow';

setBuildURLConfig({
  host: config.apiHost,
  namespace: config.apiNamespace,
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
